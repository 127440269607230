import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import JSONData from "../../../content/cemetery/cemeteryData.json"

import CemeteryBody from "../../components/cemetery/CemeteryBody"


import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/cemetery.css"

import panoImg from "../../../src/data/images/pages/cemetery/overall/pano1.jpg" // Tell Webpack this JS file uses this image

import pic1 from "../../../src/data/images/pages/cemetery/overall/cemetery_icon_1.jpg" // Tell Webpack this JS file uses this image
import pic2 from "../../../src/data/images/pages/cemetery/overall/cemetery_icon_2.jpg" // Tell Webpack this JS file uses this image


function Cemetery () {
	 const query = graphql`
	       query {
	         allFile(
	           sort: { fields: name, order: ASC }
	           filter: { relativePath: { glob: "images/pages/cemetery/*/*.*" } }
	         ) {
	           edges {
	             node {
	               id
	               name
	               relativeDirectory
	               childImageSharp {
	                 fluid{
	                   ...GatsbyImageSharpFluid
	                 }
	               }
	             }
	           }
	         }
	 	}

	 `
 			let data = useStaticQuery(query)

	return(
				<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content  cemetery">
				<CemeteryBody data={data}/>
				<div className="cemetery-blurb">
					<img className="cemetery-blurb-pic pic-left" src={pic1}/>
						<span className="blurb-text">Saint John Orthodox Cathedral Cemetery is located on Monastery Drive, across the street from the Cathedral and Saint James House. It is a private parish cemetery inteded for the burial of Orthodox Christians. It is maintained as a place of respectful repose for the departed providing a place for reflection and prayer in a peaceful natural setting.  On one side is the glass mosaic of the Theotokos and a sheltered log bench. Nearby a trail leads through the woods to the Saint Sergius of Radonezh Chapel. All are welcome to stop and enjoy the beauty and quiet.</span>
					<img  className="cemetery-blurb-pic pic-right" src={pic2}/>
				</div>

			</main>
			<Footer />		
			</div>
		)
}

export default Cemetery 