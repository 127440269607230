import React, { Component } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"

import ReactModal from "react-modal"

import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';

import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import JSONData from "../../../content/cemetery/cemeteryData.json"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/cemetery.css"

import panoImg from "../../../src/data/images/pages/cemetery/overall/pano1.jpg" // Tell Webpack this JS file uses this image

import Guidelines from "../../../static/pdf/cemetery/Cemetery_Guidelines.pdf"
import Monuments from "../../../static/pdf/cemetery/Monuments and Markers Information.pdf"
import TrisagionPrayers from "../../../static/pdf/cemetery/The Trisagion Prayers of Mercy for the Departed.pdf"

class Cemetery extends Component {
	constructor(props){
		super(props)
		this.state= {
			index:-1,
			modalBold:"",
    	  	modalText:"",
    	  	modalImage:props.data.allFile.edges[0].node.childImageSharp.fluid,
    	  	modalOpen:false,
    	  	modalImageIndex:0,
    	  	modalKeyOpen:0,
    	  	modalPhotoCountForPerson:0
		}

		this.images = {}
		this.props.data.allFile.edges.forEach(element => this.addPhotoToObject(element.node.relativeDirectory, element.node.name, element.node.childImageSharp.fluid))


		Object.keys(JSONData.people).forEach(element => JSONData.people[element]['last_name_first'] = element.split('_').reverse().join('_'))
		
		Object.keys(JSONData.people).forEach(element => this.addImagesForPerson(element))




		this.people = Object.fromEntries(
			Object.entries(JSONData.people).sort(function(a,b) {
		    	var x = a[1].last_name_first.toLowerCase();
		   		var y = b[1].last_name_first.toLowerCase();
		    	return x < y ? -1 : x > y ? 1 : 0;
			})
		);
		this.nameLis = Object.keys(this.people).map((name,key) => this.getListJSX(name,this.people[name],key))

	}
	addImageToJsonData = (person,element) => {

		console.log(element)
		if(element[0] === "headstone"){
			JSONData.people[person]['images'].unshift(element[1])
		} else {
			JSONData.people[person]['images'].push(element[1])
		}
	}
	addImagesForPerson = (person) => {
		//JSONData.people[person]['images'].push(this.images['headstones'][person])
		if(this.images.hasOwnProperty(person)){
			Object.entries(this.images[person]).forEach(element => this.addImageToJsonData(person,element)
			)
		}

	}


	highlightHeadstone = (key) => {
		 this.setState({index:key})
	}

	getDisplayName = (name) => {
	 return name.split('_')
    		.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    		.join(' ');	
	}


	getHeadstoneDivStyle = (data) => {
		const divStyle = {
		  	"minWidth":data['headstoneData']['width'],
			"paddingTop":data['headstoneData']['height'],
			"marginTop":data['headstoneData']['margin-top'],
			"marginLeft":data['headstoneData']['margin-left']
		};
		return divStyle;
	}

	openModal = (key) => {
		this.setState({
			index:-1,
    	  	modalImage:this.people[key]['images'][0],
    	  	modalKeyOpen:key,
    	  	modalImageIndex: 0,
    	  	modalPhotoCountForPerson:this.people[key]['images'].length
		})

		this.handleModalOpen()
	}
	changeModalImage = (count) => {

		const photosForPerson=this.people[this.state.modalKeyOpen]['images'].length

		const newModalImageIndex = this.state.modalImageIndex+count
		const newModalImage = this.people[this.state.modalKeyOpen]['images'][newModalImageIndex%photosForPerson]
		this.setState({
			modalImageIndex:newModalImageIndex,
			modalImage: newModalImage,
			modalPhotoCountForPerson:photosForPerson
		} )

	}

	getListJSX = (headstoneName, data,key) => {

		return (
				<li key={key} onClick={(event) => this.openModal(headstoneName)} onMouseOut={ (event) => this.highlightHeadstone(-1)} onMouseOver={ (event) => this.highlightHeadstone(key)}>
					<span className='name'>{this.getDisplayName(headstoneName)}</span>

					<span className='death-date'>{data.death_date}</span>

				</li>
			)
	}


	getHeadstoneMarkerJsx = (headstoneName,data,key) => {
		return( <div key={key} onClick={(event)=> this.openModal(headstoneName)}style={this.getHeadstoneDivStyle(data)} className='person'id={this.state.index === key ? 'show':'hide'}></div>)

	}

	addPhotoToObject = (dir,name,fluid) => {
		dir = dir.replace("images/pages/cemetery/","")
		if(this.images.hasOwnProperty(dir) == false){
			this.images[dir] = {}
		}
		this.images[dir][name] = fluid
		
	}
	
	handleModalOpen = event => {
    	this.setState({
     		modalOpen: true 
   		})
  	}

  	handleModalClose = event => {
    	this.setState({ 
      		modalOpen: false,
    	})
    
  	}
  	printMousePos = (event) => {
  		const clickX = event.clientX-410
  		const clickY = event.clientY-110


  		const totalWidth = (event.currentTarget.offsetWidth-410)
  		const totalHeight = (event.currentTarget.offsetWidth-410)

	  	console.log("%o",event)

	    console.log("clientY: " + (clickY))
	   	console.log("total height: "+(totalHeight))


	    console.log("X position: "+(clickX/totalWidth*100))
	    console.log("Y position: "+(clickY/totalHeight*100))
	    console.log()
	}

	render () {

		this.headstoneMarkerJsx = Object.keys(this.people).map((name,key) => this.getHeadstoneMarkerJsx(name,this.people[name],key))

		const forwardArrowClass= this.state.modalPhotoCountForPerson > (this.state.modalImageIndex + 1) ? "nav-arrow forward-arrow":"nav-arrow forward-arrow hidden-arrow"

		const backwardArrowClass= 0 < this.state.modalImageIndex ? "nav-arrow back-arrow" : "nav-arrow back-arrow hidden-arrow"

		return(
					
				<div  onClick={(event) => this.printMousePos(event)} className="cemetery-overview">
				
				<div className="name-list">
					<div className='list-header'>
							"Memory Eternal!"
					</div>
					<ul className='name-list'>
						{this.nameLis}
					</ul>
					<a className="download-btn cemetery-btn" href={Guidelines} target="_blank">Cemetery Guidelines</a>
					<a className="download-btn cemetery-btn" href={Monuments} target="_blank">Monuments and Markers Information</a>
					<a className="download-btn cemetery-btn" href={TrisagionPrayers} target="_blank">Trisagion Prayers For the Departed</a>
				</div>
								<div className="cemetery-header"><span>Saint John's Cemetery</span></div>

					<div className="cemetery-panorama" >

						<img src={panoImg}/>
						{this.headstoneMarkerJsx}


					</div>



					<ReactModal
		                isOpen={this.state.modalOpen}
		                onRequestClose={this.handleModalClose}
		                overlayClassName="eri-modal-overview"
		                className="cemetery-modal-content"
		              >
		                <button className="close-modal-button" onClick={this.handleModalClose}>
		                X
		                </button>
		                <div className="cemetery-modal"> 
		                  <div className={backwardArrowClass} onClick={(event) => this.changeModalImage(-1)}> 
		                  	<ArrowLeft fontSize="inherit" />
		                  </div>
           	              <div className={forwardArrowClass} onClick={(event) => this.changeModalImage(1)}> 
           	              	<ArrowRight fontSize="inherit" />
           	              </div>

		                  <Img
		                    className="cemetery-modal-image"
		                    fluid={this.state.modalImage}
		                    alt="" 
		                  />
		                  

		                </div>                 
		            </ReactModal>

				</div>

			)
	}
}
export default Cemetery 

			// <div>
			// 	{photos}
			// </div> 